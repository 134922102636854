import * as dompack from "dompack";
import Clubfinder from "./pages/clubfinder/clubfinder.es";
import * as mso from "@mod-mso/webdesigns/mso/mso";

import "@mod-mso/webdesigns/mso/mso.scss";
import "@mod-nubshop/web/pages/webshop/webshop";
import Nubshop from '@mod-nubshop/web/pages/webshop/webshop';
import "@mod-nubshop/web/components/header/header";
import './brynxz.lang.json';
import * as whintegration from "@mod-system/js/wh/integration";
import getTid from "@mod-tollium/js/gettid";
import "./brynxz.scss";
import "sweetalert2/dist/sweetalert2.min.css";

dompack.onDomReady(() => {
  new mso.MSOSite();
  new Nubshop();

  const clubfinderMap = dompack.qS("#clubfinder-map");

  if (clubfinderMap) {
    new Clubfinder(clubfinderMap, {
      resultsContainer: dompack.qS("#clubfinder-results"),
    });
  }
  
  const target = dompack.qS('.ns-product__properties');
  if(!target) return;

  target.insertAdjacentHTML('beforeend', `<a class="btn btn-primary btn-gold" href="${whintegration.config.site.clubfinderurl}">${getTid('brynxz:website.webshop.searchlocation')}</a>`);
  target.insertAdjacentHTML('beforeend', `<a class="btn btn-primary btn-gray" href="${whintegration.config.site.catalogurl}">${getTid('brynxz:website.webshop.shopretailers')}</a>`);
});
