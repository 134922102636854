/* global $ */
import "./login.scss";

import * as dompack from "dompack";
import * as whintegration from "@mod-system/js/wh/integration";

dompack.onDomReady(() => {
  if (document.documentElement.classList.contains("page-login")) {
    initLoginPage();
  }
});

// FIXME: remove jQuery dependency
function initLoginPage() {
  $(".wh-wrdauth-form").addClass("ww-grid");

  if (document.documentElement.dataset.page === "login") {
    $(".wh-form").prepend(
      `<p>Heb je al een account? Log dan hier in om eerdere bestellingen terug zien, reviews achter te laten en producten toe te voegen aan je verlanglijstje.</p>`
    );
    $(".wh-form").prepend(`<h1>Inloggen</h1>`);
    // $('.wh-wrdauth-form__intro').remove();
    $(".wh-wrdauth-login__forgotpasswordlink").text("Wachtwoord vergeten?");
    // $(".wh-wrdauth-login__forgotpasswordlinkholder").appendTo(".wh-form");
    // $('.wh-form').append(`<div class="p-login__register"><p>Heb je nog geen account, registreer je dan via onderstaande knop:</p><a href="${whintegration.config.site.pages.registration}" class="btn btn-outline-secondary w-100">Registreren</a></div>`);

    if (dompack.debugflags["debug"]) {
      $("#login-username").val("wouter@beta.webhare.net");
      $("#login-password").val("secret123");
    }
  } else if (document.documentElement.dataset.page === "forgotpassword") {
    // $('.wh-form').prepend(`<h1>Wachtwoord herstellen</h1>`);
    const emailinput = dompack.qS('input[name="email"]');
    if (emailinput) {
      emailinput.type = "email";
    }
    // $('.wh-wrdauth-forgotpassword__intro .wh-wrdauth--text').text('Voer hieronder het e-mailadres van je account in. Als het account bestaat, sturen we een herstellink om het wachtwoord opnieuw in te stellen.');
    // $('.wh-form').append(`<p class="mt-4"><a href="${whintegration.config.site.pages.login}">Terug naar inlogpagina</a>`);
  } else if (document.documentElement.dataset.page === "resetpassword") {
    // $('.wh-form').prepend(`<h1>Wachtwoord herstellen</h1>`);
  } else if (document.documentElement.dataset.page === "passwordchange") {
    // $('.wh-form').prepend(`<h1>Wachtwoord aanpassen</h1>`);
    // $('.wh-wrdauth-passwordchange__done').append(`<p class="mt-4"><a href="${whintegration.config.site.pages.app}">Terug naar applicatie</a>`);
  } else if (document.documentElement.dataset.page === "setpassword") {
    $(".wh-form").prepend(`<h1>Wachtwoord instellen</h1>`);
    $(".wh-wrdauth-setpassword__intro .wh-wrdauth--text").text(
      "Voer een wachtwoord in om het account te activeren."
    );
    $(".wh-wrdauth-setpassword__done .wh-wrdauth--text").text(
      "Je hebt je account succesvol geactiveerd en bent automatisch ingelogd."
    );
    $(".wh-wrdauth-setpassword__done").append(
      `<a class="btn btn-primary" href="${whintegration.config.obj.pages.signuppagelink}">Verder met bestellen</a>`
    );
  }

  document.documentElement.classList.add("init");
}
