// import './registration.scss';
import './account.scss';

import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import { RPCFormBase } from '@mod-publisher/js/forms';

let reviews = [];

export class AccountDetailsForm extends RPCFormBase {
  constructor(node) {
    super(node);

    if (dompack.debugflags['debug']) {
      // dompack.qS('#account-set_delivery').checked = true;
    }
  }

  async onSubmitSuccess(result) {
    if (result.submitinstruction) {
      whintegration.executeSubmitInstruction(result.submitinstruction);
    }
  }
}

dompack.onDomReady(() => {
  requestAnimationFrame(() => {
    reviews = dompack.qSA('.myreviews__review');

    setupFilterListeners();
  });
});

function setupFilterListeners() {
  const buttons = dompack.qSA('.myreviewsfilter__button');
  for (const button of buttons) {
    button.addEventListener('click', doFilter);
  }
}

function doFilter(evt) {
  evt.preventDefault();
  const activateStatuses = evt.target.dataset.filter;

  dompack.qS('.myreviewsfilter__button.selected').classList.remove('selected');

  evt.target.classList.add('selected');

  for (const review of reviews) {
    const shouldHide = !activateStatuses.includes(review.dataset.status);
    dompack.toggleClass(review, 'hidden', shouldHide);
  }
}
