import './registration.scss';

import * as dompack from 'dompack';
import { NubshopForm } from '../../shared/forms/forms';

export class RegistrationForm extends NubshopForm {
  constructor(node) {
    super(node);

    if (dompack.debugflags['debug']) {
      this.invokeRPC('debugprefill');
    }
  }
}
