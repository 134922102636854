import './product.scss';

import * as dompack from 'dompack';
import * as util from '../../shared/js/util';

dompack.onDomReady(() => {
  const isProductPage = !!dompack.qS('.ns-product');

  if (!isProductPage) return;

  requestAnimationFrame(setup);
});

let productPrice, amount, discount;
let productPriceEl;
const options = new Map();

function setup() {
  productPriceEl = dompack.qS('.ns-product__priceblock__finalprice');
  discount = Number(productPriceEl.dataset.priceDiscount) / 100 || 0;
  productPrice = Number(productPriceEl.dataset.price) / 100;
  amount = 1;

  const amountInput = dompack.qS('#product-amount');
  amountInput.addEventListener('change', (evt) => {
    evt.preventDefault();
    amount = evt.target.value;

    calculatePrice();
  });

  const optionSelects = dompack.qSA('.ns-product__optionselect');
  for (const optionSelect of optionSelects) {
    optionSelect.addEventListener('change', (evt) => {
      evt.preventDefault();
      const selectedOption = evt.target.selectedOptions[0];

      for (const option of evt.target.options) {
        options.delete(option.value);
      }

      if (selectedOption) {
        options.set(
          selectedOption.value,
          Number(selectedOption.dataset.priceChange) / 100
        );
      }

      calculatePrice();
    });
  }
}

function calculatePrice() {
  const quantityDiscount = getQuantityDiscountNewPrice(amount);
  const optionsTotal = [...options.values()].reduce((en, cur) => en + cur, 0);

  let finalPrice =
    quantityDiscount > 0
      ? (quantityDiscount + optionsTotal) * amount
      : (productPrice + optionsTotal) * amount;

  if (discount) {
    finalPrice = finalPrice - discount * amount;
  }

  productPriceEl.textContent = util.formatMoney(finalPrice);
}

function getQuantityDiscountNewPrice(amount) {
  if (!window.quantityDiscounts || !window.quantityDiscounts.length) return 0;

  const filtered = window.quantityDiscounts.filter(
    (qD) => amount >= qD.minimum_amount
  );

  if (!filtered.length) return 0;

  const quantityDiscount = filtered[
    filtered.length - 1
  ].newprice_formatted.replace(',', '.');

  return Number(quantityDiscount);
}
