import * as dompack from 'dompack';
import './ratingfield.scss';

const ratings = ['Slecht', 'Onvoldoende', 'Gemiddeld', 'Goed', 'Uitstekend'];

export class RatingField {
  constructor(node, options) {
    this.stars = node.querySelectorAll('.star-icon');
    this.input = node.querySelector('.ratingfield__input');
    this.caption = node.querySelector('.ratingfield__caption');

    this.stars.forEach((el) => {
      el.onclick = () => {
        const rating = parseInt(el.dataset.rating);
        this.input.value = rating;
        this.caption.innerText = ratings[rating - 1];
        for (let i = 1; i <= 5; i++) {
          const tmp = dompack.qS(`.star-icon[data-rating="${i}"]`);
          if (i > rating) {
            tmp.classList.remove('on');
            tmp.classList.add('off');
          } else {
            tmp.classList.remove('off');
            tmp.classList.add('on');
          }
        }
      };
    });
  }
}
