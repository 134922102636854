import * as dompack from 'dompack';
import { RPCFormBase } from '@mod-publisher/js/forms';

export class LoginForm extends RPCFormBase {
  constructor(node) {
    super(node);
    dompack.remove(node.querySelector('.wh-form__button--submit'));

    if (dompack.debugflags['debug']) {
      dompack.qS('#loginform-login').value = 'wouter@beta.webhare.net';
      dompack.qS('#loginform-password').value = 'secret123';
    }
  }
}
