import '../../shared/forms/ratingfield/ratingfield';
import './productreview.scss';

import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import { RPCFormBase } from '@mod-publisher/js/forms';
import { RatingField } from '../../shared/forms/ratingfield/ratingfield';

dompack.onDomReady(() => {
  dompack.register('.ratingfield', (node) => new RatingField(node));
});

export class ProductReviewForm extends RPCFormBase {
  constructor(node) {
    super(node);
  }

  async getFormExtraSubmitData() {
    console.log(whintegration.config.obj);
    return { product: whintegration.config.obj.productid };
  }
}
