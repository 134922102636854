import './order.scss';

import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import { RPCFormBase } from '@mod-publisher/js/forms';

export class OrderForm extends RPCFormBase {
  constructor(node) {
    super(node);

    if (dompack.debugflags['debug']) {
      this.invokeRPC('debugprefill');
      // dompack.qS('#order-set_delivery').checked = true;
      dompack.qS('#order-conditions').checked = true;
      dompack.qS('input[name="payment.paymentmethod"]').checked = true;
      dompack.qS('input[name="payment.paymentmethod"]').closest('div').querySelector('select').value = '1';
    }

    const termslink = whintegration.config.obj.termspage;
    if(termslink) {
      const target = dompack.qS('#order-termslink-anchor + .wh-form__fieldline p.normal');
      target.insertAdjacentHTML('afterbegin', `<a href="${termslink}">Klik hier voor de voorwaarden</a>`);
    } 
  }

  async onSubmitSuccess(result) {
    if(result.submitinstruction) {
      whintegration.executeSubmitInstruction(result.submitinstruction);
    }
  }
}
