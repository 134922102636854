/* global Vue, GLightbox */
import './webshop.scss';
import '../../components/header/header';

import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import * as cookie from 'dompack/extra/cookie';
import * as util from '../../shared/js/util';
import { registerHandler } from '@mod-publisher/js/forms';
import * as modal from '@mod-ww/components/modal';
import * as RPC from '../../shared/js/nubshop.rpc.json';

import '../category/category';
import '../mycart/mycart';
import '../login/login';
import '../account/account';
import '../product/product';
import '../search/search';
import '../registration/registration';
import '../signup/signup';
import '../../widgets/widgets';
import '../productreview/productreview';
import { RegistrationForm } from '../registration/registration';
import { ProductReviewForm } from '../productreview/productreview';
import { OrderForm } from '../order/order';
import { AccountDetailsForm } from '../account/account';
import { LoginForm } from '../../shared/forms/login/login';
import { insertPasswordEye } from '../../shared/js/util';

function getScrollY() {
  return window.scrollY || window.pageYOffset || document.body.scrollTop;
}

function insertPasswordViewers() {
  const pwinputs = dompack.qSA('input[type="password"]');
  pwinputs.forEach(insertPasswordEye);
}

function onScroll() {
  let scrollPos = getScrollY();

  dompack.toggleClass(document.documentElement, 'scroll', scrollPos > 0);
}

dompack.onDomReady(() => {
  const headerEl = dompack.qS('#nubshop-header');
  if (whintegration.config.obj.cartpagelink) {
    const target = dompack.qS('#ww-main-menu .ww-header__desktopmenu');
    target.insertAdjacentHTML(
      "beforeend",
      `<div class="ww-menu-item ww-header__cartpagelink">
        <a class="nubshop-header__link nubshop-header__link--cart text-nowrap" href="${whintegration.config.obj.cartpagelink}">
          <i class="fas fa-shopping-cart nubshop-header__link--icon" style="color: #000;"></i>
          <span class="nubshop-header__link--text"></span>
          <span class="badge rounded-pill bg-danger js-nubshop-cart-num-products"></span>
        </a>
       </div>`
    );
  }

  if (headerEl && headerEl.querySelector('.nubshop-header__container-usps')) {
    document.documentElement.classList.add('has-usp');
  }

  document.addEventListener('scroll', onScroll);
  onScroll();
});

export default class Nubshop {
  constructor() {
    dompack.onDomReady(() => {
      util.updateNumProductsInCart();

       // Add search functionality
      let searchbutton = dompack.qS(".nubshop-header__link--search");

      searchbutton.addEventListener("click", (evt) => {
        if (dompack.qS("html.searchexpanded") == undefined)
          evt.preventDefault();

          // Waiting for the transition to finish
          dompack.qS('html').classList.add("searchexpanded");
          setTimeout(function(){  dompack.qS("#nubshop-searchform--mobile .nubshop-search__input").focus(); }, 500);
      });

       dompack.qS("#searchoverlay").addEventListener("click", () => {
         dompack.qS("html").classList.remove("searchexpanded");
       });

      if (!document.documentElement.classList.contains('page-nubshop')) {
        document.documentElement.classList.add('init');
        return; // no need for the Vue app
      }

      const NubshopVueApp = {
        data() {
          return {
            products: [], // id, num, price_cents (inc vat)
            wishlist: [], // element reference store for products in wishlist
            appliedCoupon: null,
            couponInputValue: '',
          };
        },
        watch: {
          numProducts: function (val) {
            util.updateNumProductsInCart();
          },
        },
        computed: {
          // returns id, num, price_cents, imagelink, link, title
          enrichedProducts() {
            return this.products.map((product) => {
              return { ...this._getEnrichedProduct(product.id), ...product };
            });
          },
          numProducts() {
            return this.products.length;
          },
          appliedCouponCodeFormatted() {
            if(!this.appliedCoupon) {
              return '-';
            }

            return `- ${util.formatMoney(this.calculateCouponDiscount())}`;
          },
          shippingCostsFormatted() {
            if (whintegration.config.obj.shipping_costs_cents <= 0) {
              return util.formatMoney(0);
            }
            return util.formatMoney(whintegration.config.obj.shipping_costs_cents / 100.0);
          },
          totalArticles() {
            return this.products.reduce((val, prod) => val += prod.num * prod.price_cents, 0);
          },
          totalArticlesFormatted() {
            let totalPriceCents = this.totalArticles;

            return util.formatMoney(totalPriceCents / 100.0);
          },
          totalPriceFormatted() {
            let totalPriceCents = this.totalArticles;

            if (this.appliedCoupon !== null) {
              const couponDiscountCents = this.calculateCouponDiscount() * 100;

              totalPriceCents = Math.max(totalPriceCents - couponDiscountCents, 0);
            }

            totalPriceCents += whintegration.config.obj.shipping_costs_cents;

            return util.formatMoney(totalPriceCents / 100.0);
          },
        },
        async mounted() {
          this.products = util.getProductsFromCookie();
          this.appliedCoupon = util.getAppliedCouponFromCookie();

          const archived = [];
          this.products.forEach((prod) => {
            if (prod.archived) {
              archived.push(prod.id);
              this._removeProduct(prod.id);
            }
          });

          if (archived.length) {
            modal.runMessageBox(
              'Producten verwijderd uit winkelmand',
              'Een aantal producten zijn niet meer beschikbaar en zijn verwijderd uit uw winkelmand.',
              [{ title: 'OK', class: 'btn-secondary', type: 'cancel' }]
            );
            util.updateNumProductsInCart();
          }

          registerHandler('nubshop:order', (node) => new OrderForm(node));
          registerHandler('nubshop:login', (node) => new LoginForm(node));
          registerHandler('nubshop:accountdetailsform', (node) => new AccountDetailsForm(node));
          registerHandler('nubshop:registration', (node) => new RegistrationForm(node));
          registerHandler('nubshop:productreviewform', (node) => new ProductReviewForm(node));

          dompack.registerMissed(dompack.qS('.wh-wrdauth__loginform'));

          for (const form of dompack.qSA('form[data-wh-form-id], .wh-wrdauth__logout')) dompack.registerMissed(form);

          window.addEventListener('wh:wrdauth-loginfailed', (evt) => {
            dompack.stop(evt);
            console.error(evt);
            modal.runMessageBox('Fout bij inloggen', 'Het ingevulde e-mailadres en/of wachtwoord is incorrect.', [
              { title: 'OK', class: 'btn-secondary', type: 'cancel' },
            ]);
          });

          if (typeof GLightbox !== 'undefined') {
            /*const lightbox = */ GLightbox();
          }

          dompack.qS('#nubshop-loading').classList.add('init'); // FIXME: replace CSS with html.init check to show the loading overlay
          document.documentElement.classList.add('init');
          document.documentElement.classList.add('vue-init');
          insertPasswordViewers();
        },
        methods: {
          rpc(call, ...params) {
            return new Promise((res, rej) => {
              setTimeout(() => {
                RPC[call](whintegration.config.obj.fileid, ...params).then(res).catch(rej);
              }, 300);
            })
          },
          async addToCart(productId, priceCents) {
            const selectedOptions = this._getSelectedOptions();
            const amount = this._getSelectedAmount();

            const optionIds = selectedOptions.map(o => o.id);
            const priceExtra = selectedOptions.reduce((prev, curr) => prev + curr.pricechange, 0);
            const id = optionIds.length ? `${productId}_${optionIds.join('-')}` : `${productId}`

            this._updateProduct(id, priceCents + priceExtra, amount, { incrementType: 'add' }, productId);
            await modal.runModal(dompack.qS('#nubshop-modal-product-added'), { clone: true });
          },
          updateProductInCart(id, priceCents, num) {
            this._updateProduct(id, parseInt(priceCents, 10), parseInt(num, 10));
          },
          incrementProduct(id, priceCents) {
            this._updateProduct(id, priceCents, 1, { incrementType: 'add' });
          },
          decrementProduct(id, priceCents) {
            this._updateProduct(id, priceCents, -1, { incrementType: 'add' });
          },
          async removeFromCart(id) {
            const product = this.products.find((product) => product.id == id);
            if (!product) {
              throw `no product ${id}`;
            }

            const result = await modal.runMessageBox(
              'Product verwijderen',
              'Weet je zeker dat je dit product wilt verwijderen uit je winkelwagen?',
              [
                { title: 'Annuleren', class: 'btn-secondary', type: 'cancel' },
                { title: 'Verwijderen', class: 'btn-danger', type: 'submit' },
              ]
            );

            if (result !== 'submit') {
              return;
            }

            this._removeProduct(id);
          },
          clearCoupon() {
            this.appliedCoupon = null;
            util.setCouponCookie(null);
          },
          async verifyCouponCode(code) {
            const result = await this.rpc('VerifyCouponCode', code);

            return result.valid ? result.coupon : null;
          },
          async applyCouponCode() {
            const coupon = await this.verifyCouponCode(this.couponInputValue);

            if(!coupon) {
              modal.runMessageBox(
                'Ongeldige kortingscode',
                'De ingevulde kortingscode is niet geldig',
                [
                  {
                    title: 'Sluiten',
                    class: 'btn-primary',
                    type: 'cancel',
                  },
                ]
              );

              return;
            }

            this.appliedCoupon = coupon;
            util.setCouponCookie(coupon);

            modal.runMessageBox(
              'Kortingscode toegepast',
              'De ingevulde kortingscode is succesvol toegepast',
              [
                {
                  title: 'Sluiten',
                  class: 'btn-primary',
                  type: 'cancel',
                },
              ]
            );
          },
          async toggleWishList(productid) {
            try {
              dompack.toggleClass(dompack.qS('#nubshop-loading'), 'show', true);
              const { result } = await this.rpc('ToggleWishListItem', productid);
              const isAdded = result === 'added';
              const message = isAdded ? 'Het product is succesvol toegevoegd aan je verlanglijst' : 'Het product is verwijderd uit je verlanglijst';

              modal.runMessageBox(
                'Verlanglijst',
                message,
                [
                  {
                    title: 'Sluiten',
                    class: 'btn-primary',
                    type: 'cancel',
                  },
                ]
              );

              dompack.toggleClass(this.$refs.wishlistbtn, 'inwishlist', isAdded);
            } catch(e) {
              this.showError(e);
            } finally {
              dompack.toggleClass(dompack.qS('#nubshop-loading'), 'show', false);
            }
          },
          async removeFromWishList(productid) {
            try {
              dompack.toggleClass(dompack.qS('#nubshop-loading'), 'show', true);

              await this.rpc('ToggleWishListItem',productid);

              modal.runMessageBox(
                'Verlanglijst',
                'Het product is verwijderd uit je verlanglijst',
                [
                  {
                    title: 'Sluiten',
                    class: 'btn-primary',
                    type: 'cancel',
                  },
                ]
              );

              dompack.remove(this.wishlist.find((el) => el.id === `wishlistitem-${productid}`));
            } catch (e) {
              this.showError(e);
            } finally {
              dompack.toggleClass(
                dompack.qS('#nubshop-loading'),
                'show',
                false
              );
            }
          },
          calculateCouponDiscount() {
            if(this.appliedCoupon === null) {
              return 0;
            }

            const totalPrice = this.totalArticles / 100;
            const { amount, discounttype } = this.appliedCoupon;

            return discounttype === 'fixed' ? Number(amount) : totalPrice / 100 * Number(amount);
          },
          showError(error) {
            const showError = whintegration.config.dtapstage === "development";
            modal.runMessageBox(
              'Fout',
              `Er is iets fout gegaan tijdens je aanvraag, probeer het later opnieuw.\n${showError && error}`,
              [
                {
                  title: 'Sluiten',
                  class: 'btn-primary',
                  type: 'cancel',
                },
              ]
            );
          },
          formatMoney(amount) {
            return util.formatMoney(amount);
          },
          async _removeProduct(productId = 0) {
            this._updateProduct(productId, 0, 0);
          },
          _updateProduct(id, priceCents, num, { incrementType = '' } = {}, productId = 0) {
            if (num > 0 || incrementType === 'add') {
              // is it already in our products list?
              let productIdx = this.products.findIndex((product) => product.id == id);
              if (productIdx == -1) {
                if(productId == 0) {
                  console.error('Cannot add new product to cart without product Id');
                  return;
                }
                // it's not in the list => add it
                this.products.push({ id: id, num, priceCents, productId });
                console.log(this.products);
              } else {
                // it's already present in the product list => update number of times
                if (incrementType === 'add') {
                  this.products[productIdx].num = this.products[productIdx].num + num;
                } else {
                  this.products[productIdx].num = num;
                }
              }
            } else {
              this.products = this.products.filter((product) => product.id != id); // remove from array
              console.log(`remove product #${id}`);
            }

            // update cookie to save products
            cookie.write(util.getProductsCookieName(), JSON.stringify(this.products), {
              duration: 365,
              samesite: 'Lax',
            });

            console.log(`set product #${id},`, 'cookie set', util.getProductsFromCookie());
          },
          _getEnrichedProduct(id) {
            if (!window.enrichedProducts) {
              console.error('cannot get product image without window.enrichedProducts');
              return null;
            }
            const enrichedProduct = window.enrichedProducts.find((product) => product.id === id);
            if (!enrichedProduct) {
              console.error(`cannot get enriched product from window.enrichedProducts for #${id}`);
              return null;
            }
            return enrichedProduct;
          },
          _getSelectedOptions() {
            const selectedoptions = [];
            for (const optionselect of dompack.qSA('.ns-product__optionselect')) {
              if (optionselect.value) {
                const optionEl = dompack.qS(`option[value="${optionselect.value}"]`);
                selectedoptions.push({id: optionselect.value, pricechange: Number(optionEl.dataset.priceChange)});
              }
            }
            console.log('Options selected: ', selectedoptions);
            return selectedoptions;
          },
          _getSelectedAmount() {
            const input = dompack.qS('#product-amount');

            if(!input)
              throw new Error('Input for amount cannot be found.');

            return parseInt(input.value);
          },
          wishListRef(el) {
            this.wishlist.push(el);
          }
        },
      };

      if (typeof Vue !== 'undefined') {
        Vue.createApp(NubshopVueApp).mount('#nubshop-application');
      } else {
        insertPasswordViewers();
      }
    });
  }
}
