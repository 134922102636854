import './signup.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-nubshop--signup'))
    return;

  document.querySelector('#loginform-login').value = 'xxxx';

  if (dompack.debugflags['debug']) {
    console.log(dompack.qS('#loginform-login').value);
    console.log(dompack.qS('#loginform-login'));
    dompack.qS('#loginform-login').value = 'wouter@beta.webhare.net';
    console.log(dompack.qS('#loginform-login').value);
    dompack.qS('#loginform-password').value = 'secret123';
  }
});
