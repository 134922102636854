import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import * as RPC from '../../shared/js/nubshop.rpc.json';
import * as util from '../../shared/js/util';
import './category.scss';

// will keep this here just incase its ever needed if one requestAnimationFrame is not enough
function waitForInit() {
  return new Promise((res, _) => {
    function frame() {
      if (document.documentElement.classList.contains('init')) {
        return res();
      }

      console.count('uninitialized');

      requestAnimationFrame(frame);
    }
    requestAnimationFrame(frame);
  });
}

const activeFilters = new Map();
let priceFromInput, priceToInput;

dompack.onDomReady(async () => {
  const isCategoryPage = !!dompack.qS('.ns-category');

  const productsGrid = dompack.qS('.ns-category__productsgrid');

  const { deferred } = productsGrid.dataset;
  const productIds = deferred ? JSON.parse(deferred) : [];

  if (!isCategoryPage) return;

  // Without this event listeners are removed by Vue setup (i think)
  requestAnimationFrame(setup);
  requestAnimationFrame(initCategoryMenu);

  const productsString = await fetchDeferred(productIds);

  const div = document.createElement('div');
  div.innerHTML = productsString;

  document
    .querySelector('.ns-category__productsgrid')
    .insertAdjacentHTML('beforeend', div.innerHTML);

  const event = new Event("getdeffered");
  let html = dompack.qS("html.page-nubshop--category");

  html.dispatchEvent(event);
});

async function fetchDeferred(ids) {
  try {
    console.log(RPC);
    const result = await RPC['GetDeferredProductsHTML'](
      whintegration.config.obj.fileid,
      ids
    );

    return result.productshtml;
  } catch (error) {
    console.error(error);
  }

  return null;
}

function initCategoryMenu() {
  if (!AccessibleMenu) {
    throw new Error('AccessibleMenu not installed.');
  }

  const categoryMenu = document.querySelector('.ns-category__categorylist');

  // new AccessibleMenu.DisclosureMenu({
  //   menuElement: categoryMenu,
  //   submenuItemSelector: '.ns-category__categorylistitem--dropdown',
  //   submenuToggleSelector: '.ww-dropdown-toggle',
  // });

  let categorymenus = dompack.qSA('.ns-category__categorylistitem--dropdown');

  for (let menu in categorymenus) {
    let toggleElement = categorymenus[menu].querySelector(
      '.ww-dropdown-toggle'
    );

    toggleElement.addEventListener('click', function (event) {
      let submenu = event.target.closest('.ns-category__categorylistitem');
      submenu = submenu.querySelector('.ns-category__categorysublist');

      if (submenu.classList.contains('hide')) {
        submenu.classList = 'ns-category__categorysublist show';
        toggleElement.setAttribute('aria-expanded', true);
      } else if (submenu.classList.contains('show')) {
        submenu.classList = 'ns-category__categorysublist hide';
        toggleElement.setAttribute('aria-expanded', false);
      }
    });
  }
}

function setup() {
  // Setup price and specification filters
  const container = dompack.qS('.ns-category__filter--price');
  priceFromInput = container.querySelector('#productfilter-price-from');
  priceToInput = container.querySelector('#productfilter-price-to');

  console.info('Setup price filter events');
  priceFromInput.addEventListener('change', (evt) => {
    handlePriceFilterChange(evt, 'from');
  });
  priceToInput.addEventListener('change', (evt) => {
    handlePriceFilterChange(evt, 'to');
  });

  console.info('setup filter checkbox listeners');
  const filters = dompack.qSA('.ns-category__filterinput');
  for (const checkbox of filters) {
    checkbox.addEventListener('change', handleFilterCheckboxChange);
  }

  dompack.qS('#reset_filters').addEventListener('click', (evt) => {
    evt.preventDefault();

    priceFromInput.value = priceFromInput.getAttribute('min');
    priceToInput.value = priceToInput.getAttribute('max');

    for (const checkbox of filters) {
      activeFilters.delete(checkbox.id);
      checkbox.checked = false;
    }

    filterProducts({
      pricerange: [priceFromInput.value, priceToInput.value],
      filters: [],
    });
  });
}

function handlePriceFilterChange(evt, type) {
  evt.preventDefault();
  const val = Number(evt.target.value);

  // constraint value 1 higher/lower than other input
  if (type === 'to' && priceFromInput.value > val) {
    evt.target.value = Number(priceFromInput.value) + 1;
  } else if (type === 'from' && priceToInput.value < val) {
    evt.target.value = Number(priceToInput.value) - 1;
  }

  filterProducts({
    pricerange: [priceFromInput.value, priceToInput.value],
    filters: [...activeFilters.keys()],
  });
}

function handleFilterCheckboxChange(evt) {
  evt.preventDefault();

  const inputId = evt.target.id;
  if (evt.target.checked) {
    activeFilters.set(inputId, evt.target.dataset);
  } else {
    activeFilters.delete(inputId);
  }

  const filters = [...activeFilters.keys()];

  filterProducts({
    filters,
    pricerange: [priceFromInput.value, priceToInput.value],
  });
}

function filterProducts({ pricerange, filters }) {
  for (const product of dompack.qSA('.ns-product__container')) {
    let hide = false;

    const [lowest, highest] = pricerange;

    hide = !util.isPriceBetween(
      product.dataset.price,
      Number(lowest) * 100,
      Number(highest) * 100
    );

    const { showForFilters } = product.dataset;

    for (const id of filters) {
      if (!showForFilters.includes(id)) {
        hide = true;
        break;
      }
    }

    dompack.toggleClass(product, 'hidden', hide);
  }
}
