import * as dompack from 'dompack';
import * as cookie from 'dompack/extra/cookie';

export function getProductsCookieName() {
  if (!document.documentElement.dataset.nubshoptag)
    throw `No data-nubshoptag found on <html> element`;

  return `nubshop-products-${document.documentElement.dataset.nubshoptag}`;
}

export function getCouponCookieName() {
  if (!document.documentElement.dataset.nubshoptag)
    throw `No data-nubshoptag found on <html> element`;

  return `nubshop-coupon-${document.documentElement.dataset.nubshoptag}`;
}

export function formatMoney(amount) {
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount);
}

export function getProductsFromCookie() {
  const cookieProducts = JSON.parse(cookie.read(getProductsCookieName())) ?? [];
  console.log('cookie products', cookieProducts);
  let products = [];
  if (cookieProducts && cookieProducts.length > 0) {
    for (const product of cookieProducts) {
      products.push(product);
    }
  }
  return products;
}

export function setCouponCookie(coupon) {
  if (!coupon) {
    cookie.delete(getCouponCookieName());
    return;
  }
  cookie.write(getCouponCookieName(), JSON.stringify(coupon));
}

export function getAppliedCouponFromCookie() {
  const couponCode = JSON.parse(cookie.read(getCouponCookieName())) ?? null;
  console.log('cookie coupon', couponCode);

  return couponCode;
}

export function updateNumProductsInCart() {
  const products = getProductsFromCookie();
  for (const el of dompack.qSA('.js-nubshop-cart-num-products')) {
    el.textContent = products.length ? products.length : '';
  }
}

export function insertPasswordEye(pwinput) {
  const parentEl = pwinput.parentElement;
  const eye = document.createElement('div');
  const icon = document.createElement('span');

  icon.classList.add('eye-icon');
  eye.classList.add('eye');

  eye.appendChild(icon);

  eye.addEventListener('click', onEyeClick);

  parentEl.appendChild(eye);
}

export function isPriceBetween(price, min, max) {
  return price <= max && price >= min;
}

function onEyeClick(event) {
  event.preventDefault();
  const inputType = event.target.classList.contains('visible')
    ? 'text'
    : 'password';
  const pwinput = event.target.parentElement.querySelector(
    `input[type='${inputType}']`
  );

  if (pwinput.type === 'password') {
    event.target.classList.add('visible');
    pwinput.type = 'text';
  } else {
    event.target.classList.remove('visible');
    pwinput.type = 'password';
  }
}
