import * as forms from '@mod-publisher/js/forms';

export class NubshopForm extends forms.RPCFormBase {
  constructor(node) {
    super(node);
  }

  onSubmitException(errors, result) {
    const msg = "Er ging iets mis bij het verzenden van het formulier. Probeer het nog eens of neem contact met ons op als het probleem zich blijft voordoen.";
    console.error(msg);
    alert(msg); // FIXME: proper dialog
  }
}
