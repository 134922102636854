/* global google, OverlappingMarkerSpiderfier */

import './clubfinder.scss';

import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import $ from 'jquery';
import * as rpc from './clubfinder.rpc.json';

const range = 25; // Search within 25 km of user location

/* club: { id, lat, lng, name, website, etc }. JS adds a 'marker' object (google.maps.Marker) } */

let infoWindow,
  geocoder,
  __updatesearchpanel = false,
  __referencelatlng = null;

export default class ClubFinder {
  constructor(
    container,
    {
      resultsContainer = null,
      enableSpiderfier = true,
      center = { lat: 52.0946547, lng: 5.3456721 },
      zoom = 7,
      mapTypeControl = true,
      streetViewControl = true,
    } = {}
  ) {
    if (resultsContainer) {
      this.$resultsContainer = $(resultsContainer);
    }

    console.log('Init Clubfinder');

    dompack.qS('#clubfinder-searchform').addEventListener('submit', this.handleSearch.bind(this));
    dompack.qS('#btnReset').addEventListener('click', (e) => this.search('', true));

    this.currentClubs = []; // [{ ...data, marker: google.maps.Marker }]
    this.allclubs = [];
    this.searching = false;

    // initialize the map
    this.map = new google.maps.Map(container, {
      center,
      zoom,
      maxZoom: 15, // Most roadmap imagery is available from zoom levels 0 to 18
      mapTypeControl,
      streetViewControl,
      scrollwheel: false,
      clickableIcons: false,
    });

    if (enableSpiderfier) {
      this.oms = new OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true,
      });
    }

    // initialize the Geocoder for text search
    // geocoder = new google.maps.Geocoder();
    this.search('', true);
  }

  async handleSearch(event) {
    event.preventDefault();
    await this.search(dompack.qS('#clubfinder-searchinput').value);
  }

  setMarkersByClubs(clubs = []) {
    // remove currently shown markers on map
    // for (const club of this.currentClubs) {
    //   club.marker.setMap(null);
    // }
    // reset vars
    this.currentClubs = [];
    // reset results
    this.clearResults();

    // if no clubs found, we're done (remove any earlier search results)
    if (clubs.length === 0) {
      return;
    }

    for (let club of clubs) {
      // create a marker
      club.marker = new google.maps.Marker({
        position: new google.maps.LatLng(club.lat, club.lng),
        map: this.map,
        // title: club.name,
        clickable: true,
      });

      // add click event to the marker using spiderfier
      google.maps.event.addListener(club.marker, 'spider_click', () => this.showClubInfoWindow(club));

      // add marker to the map
      this.oms.addMarker(club.marker);

      // and add to (cached/current) markers
      // this.currentClubs.push(club);
    }

    // this.showResultsForCurrentClubs();
  }

  async search(query, skipEmpty = false) {
    if (!query && !skipEmpty) {
      // FIXME: better dialog than alert
      alert('Vult u a.u.b. een zoekterm in');
      return;
    }

    if (this.searching) {
      return;
    }

    this.searching = true;

    let clubs = (await rpc.findClubs(query)) || [];
    
    if (!query && skipEmpty) {
      this.allclubs = clubs;
    }

    console.info('FindClubs', clubs);

    // no clubs? try to use the geocoder to search by zip or location
    // if (clubs.length === 0) {
    //   this.searchByGeocoder(query);
    //   // console.info('searchByGeocoder');
    //   this.searching = false;
    //   return;
    // }

    // no clubs? try to use the geocoder to search by zip or location (OpenStreetMap)
    if (clubs.length === 0) {
      await this.geocoding(query);
      this.searching = false;
      return;
    }

    if (clubs.length === 0) {
      alert('Geen winkels gevonden in de buurt');
      // console.info('No clubs found');
      this.searching = false;
      return;
    }

    let bounds = new google.maps.LatLngBounds();

    for (const club of clubs) {
      // add location to bounds for auto fit/zoom/pan
      let loc = new google.maps.LatLng(club.lat, club.lng);
      bounds.extend(loc);
    }
    
    if (query) {
      this.map.fitBounds(bounds);
      this.map.panToBounds(bounds);
    } else {
      this.map.setCenter(new google.maps.LatLng(52.0946547, 5.3456721));
      this.map.setZoom(7);
    }
    // this.map.fitBounds(bounds);
    // this.map.panToBounds(bounds);

    this.setMarkersByClubs(this.allclubs, 0);

    this.currentClubs = clubs;
    this.showResultsForCurrentClubs();

    this.searching = false;
  }

  // disabled for now
  async searchByGeocoder(query) {
    let iszip = '0123456789'.split('').some((_) => query.indexOf(_) != -1);

    let searchstart = Date.now();

    // Do a geocoder request for the entered address
    let results = await this.getGeocoderResult(query);

    let meta = {
      dn_loctime: Date.now() - searchstart,
      ds_loctype: iszip ? 'zip' : 'city',
      ds_locquery: iszip ? '' : query,
      dn_locresults: results.length,
      ds_locsource: event ? 'button' : location.href.includes('source=product_detail') ? 'product_detail' : 'onload',
    };

    if (results.length >= 1) {
      // extract town and country from the first result
      let towncomp = results[0].address_components.find((_) => _.types.includes('postal_town'));
      let countrycomp = results[0].address_components.find((_) => _.types.includes('country'));

      if (towncomp && countrycomp) {
        meta.ds_loctown = towncomp ? towncomp.short_name : '';
        meta.ds_loccountry = countrycomp ? countrycomp.short_name : '';
      }

      meta.dn_loclat = Math.round(results[0].geometry.location.lat() * 10) / 10;
      meta.dn_loclng = Math.round(results[0].geometry.location.lng() * 10) / 10;
    }

    if (results.length > 0) {
      this.searchClubsAroundPosition(results[0].geometry.location, 0, true);
    }
  }

  // Find clubs within the current bounds of the map
  async searchClubsWithinBounds(selectId, updatesearchpanel, bounds) {
    if (updatesearchpanel) {
      // remember setting until rpc is finished (case rpc updates canceled)
      __updatesearchpanel = updatesearchpanel;
    }

    let param = {
      sw: { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
      ne: { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
      zoom: this.map.getZoom(),
      center: __updatesearchpanel ? __referencelatlng : {},
    };

    if (rpc.activerequest) {
      for (let c = 0; c < rpc.requestqueue.length; c++) {
        if (rpc.requestqueue[c].request.method === 'SearchClubsWithinBounds') {
          rpc.requestqueue[c].cancel(); // cancel active rpc request
        }
      }
    }

    const result = await rpc.searchClubsWithinBounds(param.sw, param.ne, param.zoom, -1, param.center);
    // console.info('SearchClubsWithinBounds', result);
    this.setMarkersByClubs(result, selectId);

    __updatesearchpanel = false;
  }

  searchClubsAroundPosition(position, selectId, updatesearchpanel) {
    __referencelatlng = position;

    // Calculate a range around the position
    let distance = (range * 1000) / Math.sqrt(2);
    let ne = util.moveLatLng(position, 45, distance);
    let sw = util.moveLatLng(position, 225, distance);
    let bounds = new google.maps.LatLngBounds(sw, ne);

    // Fit the range bounds in the map and find clubs within the displayed bounds
    this.map.fitBounds(bounds);
    this.searchClubsWithinBounds(selectId, updatesearchpanel, bounds);

    // this.drawSearchBounds(bounds); // for debugging

    return bounds;
  }

  clearResults() {
    if (this.$resultsContainer) {
      this.$resultsContainer.empty();
    }
  }

  showResultsForCurrentClubs() {
    if (!this.$resultsContainer) {
      return;
    }
    // sort by name descending
    this.currentClubs.sort((a, b) => (a.name < b.name ? -1 : 1));

    for (const club of this.currentClubs) {
      // setup HTML for search result block
      const $block = $(this.getSearchBlockHTML(club));

      // add click event
      $block.find('.js-clubfinder-showonmap').click((evt) => {
        evt.preventDefault();
        this.scrollToMap();
        this.showClubInfoWindow(club);
      });

      this.$resultsContainer.append($block);
    }
  }

  scrollToMap() {
    $('html,body').animate(
      {
        scrollTop: $('#clubfinder-map').offset().top,
      },
      500
    );
  }

  getSearchBlockHTML(club) {
    const title = `Brynxz Club ${club.name}`;
    const link = '<a href="#" class="js-clubfinder-showonmap">Toon op kaart</a>';

    let s = `<div class="c-result">
               <div class="c-result__title">${club.name} | ${link}</div>
               <div class="c-result__address">${club.address}, ${club.postal_code}</div>`;

    if (club.contact) {
      s += `<div class="c-result__contact">${club.contact}</div>`;
    }

    if (club.website) {
      s += `<a href="${club.website}" target="_blank">${club.website}</a>`;
    }

    return `${s}</div>`;
  }

  showClubInfoWindow(club) {
    if (infoWindow) {
      infoWindow.close();
    }

    infoWindow = new google.maps.InfoWindow({
      content: `<a class="c-infowindow" href="${club.website}" target="_blank">
                  ${club.name}<br />
                  <br />
                  ${club.telephone ? `Telefoon: ${club.telephone}<br />` : ''}
                  Contactpersoon: ${club.contact}<br />
                  Adres: ${club.address}, ${club.postal_code} ${club.residence}<br />
                </a>`,
      maxWidth: 500,
    });

    infoWindow.open(this.map, club.marker);
  }

  drawSearchBounds(bounds) {
    new google.maps.Rectangle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: this.map,
      bounds,
    });
  }
  // Disabled for now
  getGeocoderResult(address) {
    let deferred = dompack.createDeferred();

    let geocodeOpts = { address, bounds: this.map.getBounds(), region: 'nl' };
    if (whintegration.config.obj.cctld) {
      geocodeOpts.region = whintegration.config.obj.cctld;
    }

    geocoder.geocode(geocodeOpts, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK || status === google.maps.GeocoderStatus.ZERO_RESULTS) {
        // Remove results not given in displaycountries
        if (whintegration.config.site.displaycountries && whintegration.config.site.displaycountries.length) {
          for (let i = results.length - 1; i >= 0; --i) {
            for (let c = results[i].address_components.length - 1; c >= 0; --c) {
              let rec = results[i].address_components[c];
              if (
                rec.types.indexOf('country') > -1 &&
                whintegration.config.site.displaycountries.indexOf(rec.short_name) == -1
              ) {
                results.splice(i, 1);
                break;
              }
            }
          }
        }

        deferred.resolve(results || []);
      } else {
        deferred.reject(new Error(status));
      }
    });

    return deferred.promise;
  }

  // Using openstreetmap api
  async geocoding(address = "") {
    if (address == "")
      return;

    const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${address}&format=json&limit=1`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    const geoData = await response.json();

    if (geoData.length < 1) {
      alert('Plaats onbekend');
      return;
    }

    // Rotterdam geodata (for testing)
    // const geoData = [{
    //   "lat": "51.9244424",
    //   "lon": "4.47775",
    // }];

    const baseLat = parseFloat(geoData[0].lat);
    const baseLon = parseFloat(geoData[0].lon);

    const km = 10;

    // Convert km to lat and lng
    const kmRangeLat = (km * (1 / 110.574)).toFixed(7);
    const kmRangeLon = (km * (1 / (111.320 * Math.cos(1)))).toFixed(7);

    const sw = {
      "lat": baseLat - kmRangeLat,
      "lng": baseLon - kmRangeLon,
    };

    const ne = {
      "lat": parseFloat(baseLat) + parseFloat(kmRangeLat),
      "lng": parseFloat(baseLon) + parseFloat(kmRangeLon),
    }
    
    const result = await rpc.searchClubsWithinBounds(sw, ne, 5, -1, { "lat": baseLat, "lng": baseLon });

    if(result.length < 1) {
      alert('Geen winkels gevonden');
      this.search('', true);
      return;
    }

    this.setMarkersByClubs(this.allclubs, 0);

    this.currentClubs = result;
    this.showResultsForCurrentClubs();

    let bounds = new google.maps.LatLngBounds(sw, ne);

    // Fit the range bounds in the map and find clubs within the displayed bounds
    this.map.fitBounds(bounds);
  }
}
